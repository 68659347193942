<template>
  <div>    

    <div class="its">
      <SkDiary 
        v-for="(o, k) in Array.from(Array(20).keys())"
        :key="k"
        />              
    </div>
  
  </div>
</template>


<script>
 
export default {
  components: {    
    
  },
  data() {
    return {
 
    };
  },
  methods: { 
 
  }
}
</script>
  

<style scoped>
  
.ttl{  
  width: 250px;
  height: 30px;  
  margin-bottom: 10px;  
}
.its{
  display: flex;
  flex-wrap: wrap;  
  width: calc(100% + 20px);  
  margin-bottom: 30px;  
} 



</style>
